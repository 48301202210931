import $ from "jquery";

$(document).on('turbolinks:load', function () {
  if ($('.admin_dm_provider_form').length) {

    // =========================
    // =        BILLING        =
    // =========================

    $('#billingModal').on('show.bs.modal', function (e) {
      $('#create_billing_form')[0].reset();
      $('#billing_commune_id').trigger('change');
      $('#billing_bank_account_id').trigger('change');
    });

    let createdBillingId = null;

    $(document).on('ajax:success', '#create_billing_form', function (e) {
      let data = e.detail[0];
      if (data.success) {
        createdBillingId = data.billing.id;
        $('#billingModal').modal('hide');
      } else {
        // Disponible para agregar mensaje en el popup
      }
    });

    $('#billingModal').on('hidden.bs.modal', function (e) {
      // Determinamos el valor que vamos a re-seleccionar
      let previouslySelectedValue = $('#dm_provider_billing_id').val();
      let selectedValue = createdBillingId || previouslySelectedValue;

      createdBillingId = null;

      // Llamamos al endpoint para recargar la lista
      $('#dm_provider_billing_id').empty();
      let url = $("#dm_provider_billing_id").data("url");
      $.get(url, function (data) {

        // Opción por defecto
        if (!selectedValue) {
          $('#dm_provider_billing_id')
            .append("<option selected='selected'>Seleccionar facturación</option>");
        }

        // Opciones desde API
        data.options.forEach(function (option) {
          if (option[1] === parseInt(selectedValue)) {
            $('#dm_provider_billing_id')
              .append("<option selected value=" + option[1] + ">" + option[0] + "</option>");
          } else {
            $('#dm_provider_billing_id')
              .append("<option value=" + option[1] + ">" + option[0] + "</option>");
          }
        });
      });
    })

    // =========================
    // =     BANK ACCOUNT      =
    // =========================

    $('#bankAccountModal').on('show.bs.modal', function (e) {
      $('#create_bank_account_form')[0].reset();
      $('#bank_account_bank_id').trigger('change');
      $('#bank_account_account_type').trigger('change');
    });

    let createdBankAccountId = null;

    $(document).on('ajax:success', '#create_bank_account_form', function (e) {
      let data = e.detail[0];
      if (data.success) {
        createdBankAccountId = data.bank_account.id;
        $('#bankAccountModal').modal('hide');
      } else {
        // Disponible para agregar mensaje en el popup
      }
    });

    $('#bankAccountModal').on('hidden.bs.modal', function () {
      // Determinamos el valor que vamos a re-seleccionar
      let previouslySelected = $('#billing_bank_account_id').val();
      let selectedValue = createdBankAccountId || previouslySelected;

      createdBankAccountId = null;

      // Llamamos al endpoint para recargar la lista
      $("#billing_bank_account_id").empty();
      let url = $('#billing_bank_account_id').data("url");
      $.get(url, function (data) {

        // Opción por defecto
        if (!selectedValue) {
          $('#billing_bank_account_id')
            .append("<option selected='selected'>Seleccionar cuenta bancaria</option>");
        }

        // Opciones desde API
        data.options.forEach(function (option) {
          if (option[1] === parseInt(selectedValue)) {
            $('#billing_bank_account_id')
              .append("<option selected value='" + option[1] + "'>" + option[0] + "</option>");
          } else {
            $('#billing_bank_account_id')
              .append("<option value='" + option[1] + "'>" + option[0] + "</option>");
          }
        });
      });
    });

  }
});
